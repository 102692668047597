<template>
    <div style="background-color: #f5faff; z-index: 0; height: 100vh">
      <!-- <NavBar
      style="max-height: 50px;margin-bottom: 80px;"
      :username="currentUserName"
      :pageName="pageName"
      class="docVideoCallNavbar"
      ></NavBar> -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="exitModal" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
               Prescription has been saved successfully.<br>Do you want to Exit?
             </v-card-title>
             <v-card-actions>
              <v-btn color="primary" depressed @click="closeExitModal" style="background-color: #1467BF;">
                OK
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="warning" depressed @click="()=> {}" style="background-color: #EB5757;">
                Cancel
              </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-dialog v-model="prescriptionDialog" persistent max-width = "50%">
            <v-card style="padding: 40px 30px">
              <v-row>
                <v-col cols="12">
                  <div style="display: flex; flex-direction: row; justify-content: space-between;  margin-top: 10px">
                    <div style="display: flex; flex-direction: row;">
                     <div> <p>Booking ID:</p></div>
                     <div><span style=" font-weight: 600">{{
                        view_prescription_object.appointment_reference_number
                      }}</span></div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
  
      <v-app style="background-color: #f5faff" align="center">
        <v-row>
          <v-col cols="3" sm="3" lg="3" md="3" style="top: 60px; height: 90vh;" class="videoAndPrescriptionContainer">
            <v-row v-if="!call_ended">
              <v-col cols="1">
              </v-col>
              <v-col  cols="11"
                sm="11">
  
                <div id="video-container" class="row remote_video_container">
                  <div id="remoteTrack"></div>
                  <div id="localTrack"></div>
                </div>
                <div style="display:flex; flex-direction: row;justify-content: space-evenly; margin-top: 30px;" v-if="callConnected">
                  <div style="margin: 2px;" v-if="videoBtnFlag && deviceIDList.length > 1">
                  <v-btn
                      elevation="8"
                      style="  background: #FFFFFF;border-radius: 8px; color:white;"
                      @click="switchCamera(activeRoom)"
                      icon
                      ><img
                        src="https://s3iconimages.mymedicine.com.mm/switchCamera.svg"
                    /></v-btn>
                    </div>
                  <div style="margin: 2px;" v-if="videoBtnFlag">
  
                    <v-btn
                      elevation="8"
                      style="  background: #FFFFFF;border-radius: 8px; color:white;"
                      @click="turnVideoOff"
                      icon
                      ><img
                        src="https://s3iconimages.mymedicine.com.mm/CameraOn.svg"
                    /></v-btn>
                  </div>
                  <div style="margin: 2px;" v-else>
                    <v-btn
                      elevation="8"
                      style="  background: #FFFFFF;border-radius: 8px; color:white;"
                      @click="turnVideoOn"
                      icon
                      ><img
                        src="https://s3iconimages.mymedicine.com.mm/CameraOff.svg"
                    /></v-btn>
                  </div>
                  <div style="margin: 2px;" v-if="audioBtnFlag">
                    <v-btn
                      elevation="8"
                      style="  background: #FFFFFF;border-radius: 8px; color:white;"
                      @click="turnAudioOff"
                      id="muteAudio"
                      icon
                      ><img src="https://s3iconimages.mymedicine.com.mm/micOn.svg"
                    /></v-btn>
                  </div>
                  <div v-else style="margin: 2px;">
                    <v-btn
                      elevation="8"
                      style="  background: #FFFFFF;border-radius: 8px; color:white;"
                      @click="turnAudioOn"
                      icon
                      ><img src="https://s3iconimages.mymedicine.com.mm/micOff.svg"
                    /></v-btn>
                  </div>
                  <div style="margin:2px">
                    <v-btn elevation="8"  style="background: #EB5757; border-radius: 8px; position: relative; bottom: 0; margin-bottom: 30px;" @click="endTwilioVideoCall" icon ><img src="https://s3iconimages.mymedicine.com.mm/leaveCall.svg" ></v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-else style="margin-top: 50%; margin-left: 30%;">
              <div align="center" v-show="false">
                Call disconnected
              </div>
              <div align="center" style="margin-top: 30%">
                <p>Call has been disconnected</p>
              </div>
              <div align="center" v-show="false">
                <v-btn class="primary" style="margin-top: 30%;">Reconnect</v-btn>
              </div>
            </v-row>
          </v-col>
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="isLoading"
            style="position: fixed;top: 50%;left: 50%;"
          ></v-progress-circular>
        </v-row>
      </v-app>
    </div>
  </template>
  
  <script>
  import axios from "axios";
//   import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
//   import { handleError } from '../../../utils/utils';
  const Twilio = require("twilio-video");
  export default {
    name: "AdminVideoCall",
    components: {
    },
    data() {
      return {
        buttonDisableFlag: false,
        countDownTimer : "",
        currentUser: "",
        currentUserName: "",
        pageName: "DoctorVideoCall",
        booking_id: "",
        doctor_id: "",
        patientDetails: "",
        appointment_url: "",
        age: "",
        appointmentDetails: "",
        allAttachments: "",
        specialization: "",
        patient_id: "",
        nameOfPatient: "",
        tab: null,
        prescriptionDetails: "",
        items: [
        { tab: "Write Prescription", content: "Tab 1 Content" },
        { tab: "Prescription History", content: "Tab 2 Content" },
        ],
        appointment_reference_number: "",
        age: "",
        prescriptionId: "",
        particularPrescriptionDetails: "",
        unstable_connectivity: false,
        patient_no_show: false,
        referred_to_specialist: false,
        need_follow_up: false,
        exitModal:false,
        exams: "",
        suggestions: "",
        current_taking_medicines: "",
        diagnosed_with: "",
        allergies: "",
        selected: [],
        date_of_appointment:"",
        isLoading : false,
        appointmentTimeOverModal: false,
        showTimer : false,
        appointmentStartMessageModal: false,
        timeSlot:"",
        dateOfAppointment:"",
        presc_dialog: false,
        description:"",
        break_here_for_newtemplate_variable: "",
        presenting_symptoms: "",
        medical_history: "",
        surgical_history: "",
        family_history: "",
        current_taking_medicines: "",
        allergies: "",
        vaccination_history: "",
        diagnosed_with: "",
        investigations_needed: "",
        prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
        frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
        follow_up_list: ["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
        come_after: "",
        others: "",
        specialization_list: [],
        selected_specialization: "",
        followUpDate: "",
        prescriptionDialog: false,
        view_prescription_object: "",
        view_selected_prescription: "",
        sessionId:"",
        sessionToken:"",
        publisherData:"",
        videoStatus: true,
        audioStatus : true,
        videoBtnFlag: true,
        audioBtnFlag: true,
        intervalOne:"",
        call_ended: false,
        current_epoch_time: "",
        sessionDetails:"",
        twilioMeetLink: null,
        prescription_fill_flag: true,
        twilioRoomName: null,
        container: null,
        activeRoom: null,
        callConnected: false,
        remoteParticipant:null,
        menu: false,
        deviceIDList:[],
        deviceID:"",
        currentCamIndex:0,
        connectTrack:"",
        currentFacingMode:"user"
      };
    },
    beforeRouteLeave: function(to, from, next) {
      from = this.sessionId;
      //var sessionDetails = OT.initSession(process.env.VUE_APP_OPEN_TOK_API_KEY, from);
      this.endTwilioVideoCall();
  
      next();
    },
  
    mounted() {
        const events = [
          "pagehide",
          "unload"
        ];
        const eventLogger = event => {
                switch (event.type) {
                case "pagehide":
                this.endTwilioVideoCall()
                break;
                default:
                this.endTwilioVideoCall()
                break;
            }
          };
        events.forEach(eventName =>
            window.addEventListener(eventName, eventLogger)
          );
      // navigation testing ends
      this.isLoading = true
      document.title = "Video Consultation"
      this.currentUser = this.$cookies.get("token");
      var presc_dets = "";
      if (!this.currentUser) {
        this.$router.push({
          name: "AdminLogin",
        });
      } else {
        var params = this.$route.params;
        this.booking_id = params["booking_id"];
        var data = {
          token : this.currentUser,
          typeOfUser : "ADMIN",
          bookingId: this.booking_id,
          page: 1,
          limit: 8
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + '/adminVideoCallPageTwilio', data)
        .then((successResponse) => {
            this.twilioMeetLink =successResponse.data.twilio_token;
              this.twilioRoomName = successResponse.data.appointment_data.meeting_link;
              if(this.twilioMeetLink && this.twilioRoomName)
              {
                this.container = document.getElementById("video-container");
                this.startRoom();
              }
          else
          {
            this.countDownTimer = "00:00";
            this.appointmentTimeOverModal = true;
          }
        })
        .catch((errorResponse) => {
        //   handleError(errorResponse, this.$router, 'doctorVideoCallPageTwilio', 'DoctorLogin', 'doctorToken');
        });
  }
  },
  
  methods: {
    async switchCamera(room) {
        const cameraTrack = this.connectTrack.find(track => track.kind === 'video');
        this.currentFacingMode = this.currentFacingMode === "user" ? "environment" : "user"
        cameraTrack.restart({ facingMode: this.currentFacingMode });
        // const localParticipant = this.activeRoom.localParticipant;
        // localParticipant.videoTracks.forEach((trackPublication) => {
        //   let newVideoDevicelabel = trackPublication.track.mediaStreamTrack.label
        //   let sampleData = trackPublication.track.mediaStreamTrack
        //   let all_devices = "";
        //   let newDeviceData = this.deviceIDList.find( obj => obj.name !=  newVideoDevicelabel )
        //   this.deviceID = newDeviceData.deviceID;
        //   trackPublication.unpublish();
        //   trackPublication.track.stop();
        //   trackPublication.track.detach();
        // });
        // // {deviceId : {exact: this.deviceID}},
  
        // //rotate through list index
        // this.currentCamIndex = (this.currentCamIndex + 1) % this.deviceIDList.length;
        // var currentDeviceID = this.deviceIDList[this.currentCamIndex].deviceID;
        // Twilio.createLocalVideoTrack( {deviceId : {exact: currentDeviceID}},{ audio: true, video: { width: 100,height: 125}, name:this.currentUserName}).then(function(localVideoTrack) {
        //     // const participantDiv = document.getElementById(localParticipant.identity);
        //     // participantDiv.remove()
        //     // var children = participantDiv.children;
        //     // for (var i = 0; i < children.length; i++) {
        //     //   var tableChild = children[i];
        //     //   console.log(tableChild.tagName);
        //     //   if(tableChild.tagName == 'VIDEO')
        //     //   {
        //     //     tableChild.remove();
        //     //   }
        //     // }
        //     localParticipant.publishTrack(localVideoTrack);
        // }.bind(this))
      },
    endTwilioVideoCall()
    {
    
      if(this.activeRoom)
      {
        this.activeRoom.disconnect();
        this.leaveLogEvent("Leave")
        clearInterval(this.intervalOne);
        this.call_ended = true;
      }
    },
    submit_prescription(){
        this.presc_dialog = true;
      },
    async startRoom(){
        const room = await this.joinVideoRoom(this.twilioRoomName, this.twilioMeetLink);
        this.activeRoom = room;
        this.handleConnectedParticipant(room.localParticipant,"local");
        room.participants.forEach(this.handleConnectedParticipant);
        room.on("participantConnected", this.handleConnectedParticipant);
        room.on("participantDisconnected", this.handleDisconnectedParticipant);
        room.on('disconnected', room => {
            // Detach the local media elements
            room.localParticipant.tracks.forEach(publication => {
              publication.track.stop();
              const attachedElements = publication.track.detach();
              console.log("unsubscribed from: " + publication.track)
              attachedElements.forEach(element => element.remove());
            });
        });
        this.callConnected = true;
        navigator.mediaDevices.enumerateDevices().then((cams) =>{
            cams.forEach(mediaDevice => {
              if (mediaDevice.kind === 'videoinput') {
                this.deviceIDList.push({name:mediaDevice.label, deviceID: mediaDevice.deviceId})
              }
            })
          });
        // this.timerOne();
          this.isLoading = false;
    },
    handleConnectedParticipant(participant,type){
      console.log("Handel Connected Participants")
        const participantDiv = document.createElement("div");
        participantDiv.setAttribute("id", participant.identity);
        this.container.prepend(participantDiv);
        if(type != "local"){
          this.remoteParticipant = participant
        }
  
        participant.tracks.forEach((trackPublication) => {
            this.handleTrackPublication(trackPublication, participant, type);
        });
  
        participant.on("trackPublished", this.handleTrackPublication);
    },
    handleTrackPublication(trackPublication, participant, type){
      console.log("Handel Track Publication", trackPublication, participant, type)
        function displayTrack(track, type){
          console.log("Displat Track Function")
            var participantDiv = document.getElementById(participant.identity);
            participantDiv.style.transform = "scale(-1,1)";
            participantDiv.append(track.attach());
            if(type != "local")
            {
              for(var i = 0; i < participantDiv.childElementCount; i++)
              {
                if(participantDiv.children[i].tagName == 'VIDEO')
                  participantDiv.children[i].style.height = "70vh";
              }
            }
            else if (type == "local")
            {
              for(var i = 0; i < participantDiv.childElementCount; i++)
              {
                if(participantDiv.children[i].tagName == 'VIDEO')
                {
                  participantDiv.children[i].style.position = "absolute";
                  participantDiv.children[i].style.bottom = "70px";
                  participantDiv.children[i].style.right = "0px";
                  participantDiv.children[i].style.zIndex = "999";
                  participantDiv.children[i].style.height = "10vh";
                }
              }
            }
        }
        if(!participant){
          console.log("Rparticipant", participant)
          participant = this.remoteParticipant
  
          const CurrentparticipantDiv = document.getElementById(participant.identity);
          // CurrentparticipantDiv.remove();
          var children = CurrentparticipantDiv.children;
          for (var i = 0; i < children.length; i++) {
            var tableChild = children[i];
            if(tableChild.tagName == 'VIDEO')
            {
              tableChild.remove();
            }
          }
  
          // const NewparticipantDiv = document.createElement("div");
          // NewparticipantDiv.setAttribute("id", participant.identity);
          // this.container.prepend(NewparticipantDiv);
        }
        if (trackPublication.track) {
            displayTrack(trackPublication.track, type);
        }
  
        trackPublication.on("subscribed", displayTrack);
    },
    handleDisconnectedParticipant(participant){
      console.log("Handel Disconnected")
      console.log("Remove", participant )
        participant.removeAllListeners();
        const participantDiv = document.getElementById(participant.identity);
        participantDiv.remove();
    },
    async joinVideoRoom(roomName, token){
      const tracks = await Twilio.createLocalTracks({
          audio: true,
          video: { width : 425, height: 300, facingMode:this.currentFacingMode },
      });
      this.connectTrack = tracks
        const room = await Twilio.connect(token, { room: roomName, tracks });
        return room;
    },
    ViewFile(filename)
    {
      var name_arr = filename.split("/");
      var name = name_arr[name_arr.length -1];
      var read_body = {
        file_name: name,
        token : this.currentUser,
        typeOfUser : "DOCTOR",
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
      .then((readSuccess) =>{
        //window.open(readSuccess.data.data,'_blank');
        var url = readSuccess.data.data;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
          if (this.status == 200) {
            var myBlob = this.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(myBlob);
            link.download = name;
            link.click();
          }
        };
        xhr.send();
      })
      .catch((readError) =>{
        console.log(readError);
        window.alert("Error while reading the file");
      });
    },
    closePrescriptionDialogue(){
      this.prescriptionDialog = false;
      this.view_prescription_object = "";
      this.view_selected_prescription = "";
    },
    leaveLogEvent(event){
        if(event === "Leave")
        {
           var leaveLogEventBody={
  
                bookingId : this.booking_id,
                 userType: "DOCTOR",
                token : this.currentUser,
                typeOfUser : "DOCTOR"
           }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",leaveLogEventBody)
            .then((exitCallLogEntryResponse)=>{
  
            })
            .catch((exitCallLogEntryError)=>{
  
          });
  
        }
    },
    joinLogEvent(event){
        if(event === "Join")
        {
           var JoinLogEventBody={
  
                bookingId : this.booking_id,
                 userType: "DOCTOR",
                token : this.currentUser,
                typeOfUser : "DOCTOR"
           }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",JoinLogEventBody)
            .then((joinCallEntryResponse)=>{
  
            })
            .catch((joinCallEntryError)=>{
          });
        }
    },
    closeExitModal(){
      this.exitModal = false;
       this.$router.push({
        name: "ViewAppointmentDoctor",
      });
    },
    redirectToViewAppointment(){
      this.exitModal = false;
    },
    sleep(booking_id,token,router){
     setTimeout(function(){
       var changeAppointmentStatusOnCompletiondata={
        bookingId : booking_id,
        token : token,
        typeOfUser : "DOCTOR"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/changeAppointmentStatusOnCompletion",changeAppointmentStatusOnCompletiondata)
      .then((changeAppointmentStatusOnCompletionSuccess)=>{
        this.$router.push({
          path:'/doctor/viewappointment/' + booking_id
        })
      })
      .catch((changeAppointmentStatusOnCompletionError)=>{
       router.push({
        path:'/doctor/viewappointment/' + booking_id
      })
     });
    },5000);
    },
    timerOne(){
    var startTime = new Date(this.appointmentDetails.booking_epoch_time).getTime();
    var currentTime = new Date().getTime();
    if(startTime - currentTime > 600000){
      this.showTimer = true;
      // this.countDownTimer = "";
    }
    if(this.showTimer){
      var countDownDate = new Date(this.appointmentDetails.booking_closing_epoch_time).getTime() ;
      var today= new Date(this.current_epoch_time).getTime();
       this.intervalOne = setInterval(() =>{
        today = today + 1000
        var distance = countDownDate - today;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.countDownTimer = String(minutes).padStart(2,0)+":"+ String(seconds).padStart(2,0);
        if(distance < 0){
          clearInterval(this.intervalOne);
          this.countDownTimer = "00:00";
          this.appointmentTimeOverModal = true;
          this.endTwilioVideoCall();
          // this.sleep(this.booking_id,this.currentUser,this.$router);
           //this.timerTwo();
        }
      },1000);
    }
    else{
      this.appointmentStartMessageModal = true;
    }
    },
    timerTwo(){
    this.executeSecondTimer = true;
    var endTime = this.appointmentDetails.time_slot.split("-")[1]
    var bufferTime = Number(endTime.split(":")[1]) + 3;
    var combinedTime = endTime.split(":")[0] + ":" + bufferTime
    var countDownDate = new Date(this.appointmentDetails.date_of_appointment + " " +String(combinedTime)+":00 GMT+0630").getTime() ;
    var  today= new Date().getTime();
    var distance = countDownDate - today;
    var intervalTwo = setInterval(() =>{
      distance = distance - 1000;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countDownTimer = String(minutes.padStart(2,0))+":"+ String(seconds.padStart(2,0));
      if(distance < 0){
      clearInterval(intervalTwo);
      this.countDownTimer = "00:00";
      this.appointmentTimeOverModal = true;
      this.executeSecondTimer = false;
    }
    },1000);
    },
    save_prescription() {
          this.buttonDisableFlag = true;
      var DateInDateformat = new Date(this.dateOfAppointment)
          if(this.come_after == "One Week"){
            DateInDateformat.setDate(DateInDateformat.getDate()+7)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
          else if(this.come_after == "Two Week"){
            DateInDateformat.setDate(DateInDateformat.getDate()+14)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
          else if(this.come_after == "One Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+1)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
  
          }
          else if(this.come_after == "Two Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+2)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
          else if(this.come_after == "Three Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+3)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
      var presc_body = {
        covidRelatedSymptoms: this.selected,
        allergies: this.allergies,
        diagnosedWith: this.diagnosed_with,
        currentMedication: this.current_taking_medicines,
        suggestion: this.suggestions,
        examinationTBD: this.exams,
        followUp: this.need_follow_up,
        patientNoShow: this.patient_no_show,
        referredSpecialist: this.referred_to_specialist,
        unstableConnection: this.unstable_connectivity,
        appointmentReferenceNo: this.appointment_reference_number,
        doctorName: this.currentUserName,
        doctorId: this.doctor_id,
        customerName: this.nameOfPatient,
        customerId: this.patientDetails._id,
        appointmentDate: this.dateOfAppointment,
        specialization: this.specialization,
        break_here_for_newtemplate_variable: "",
        presentSymptoms: this.presenting_symptoms,
        medicalHistory: this.medical_history,
        surgicalHistory: this.surgical_history,
        familyHistory: this.family_history,
        currentMedication: this.current_taking_medicines,
        allergies: this.allergies,
        vaccinationHistory: this.vaccination_history,
        diagnosedWith: this.diagnosed_with,
        investigationNeeded: this.investigations_needed,
        comeAfter: this.come_after ? this.come_after : "Not Required",
        followUpDate: this.followUpDate ? this.followUpDate : "",
        referredSpecialistName: this.selected_specialization.specialization_name != 'Others' ? this.selected_specialization.specialization_name : this.others || '',
        token : this.currentUser,
        typeOfUser : "DOCTOR"
      }
          var prescriptions = [];
      this.prescription_object.forEach(function(item, index, object) {
        if (item.medicine !== '') {
          prescriptions.push(item);
        }
      });
      if(prescriptions.length == 0)
        presc_body.prescription = [];
      else if(prescriptions.length > 0)
        presc_body.prescription = prescriptions;
      axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
      .then((createPrescriptionResponse) =>{
        this.exitModal = true
        this.buttonDisableFlag = false;
        //window.alert("Prescription Saved");
      })
      .catch((createPrescriptionError) =>{
        this.buttonDisableFlag = false;
        if(createPrescriptionError.response.status == 403)
          window.alert("Cannot update a blank prescription");
      });
    },
    add_rec() {
      this.prescription_object.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
    },
    remove_rec(medicine,dosage,frequency,duration) {
      this.prescription_object.forEach(function (item, index, object) {
        if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
        {
          object.splice(index, 1);
        }
      });
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("doctorToken");
      })
      .catch((error) => {
      });
      this.$router.push({
        name: "DoctorLogin",
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    viewPrescription(prescriptionID) {
      this.prescriptionId = prescriptionID;
      // let routeData = this.$router.push({path: "/doctor/viewPrescription/"+ prescriptionID});
      var prescription_body = {
        "prescriptionId":prescriptionID,
        "token" : this.currentUser,
        "typeOfUser" : "DOCTOR"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/readParticularPrescription",prescription_body)
      .then((readParticularPrescriptionResponse) =>{
        this.view_prescription_object = readParticularPrescriptionResponse.data.data;
        let obj = this.specialization_list.find(o => o['specialization_name'] === this.view_prescription_object.referredSpecialistName);
        if(obj)
        {
          this.view_selected_prescription = obj;
        }
        else if(this.view_prescription_object)
        {
          this.view_selected_prescription = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
          this.view_prescription_object.others = this.view_prescription_object.referredSpecialistName;
        }
      })
      .catch((readParticularPrescriptionError) =>{
        console.log(readParticularPrescriptionError);
        window.alert("Something went wrong");
      });
      this.prescriptionDialog = true;
    },
    closeDialogAppointmentTimeOverModal(){
      // this.$router.push({
      //   path:'/doctor/viewappointment/' + this.booking_id
      // });
      this.appointmentTimeOverModal = false;
      this.call_ended = true;
    },
    closeDialogAppointmentStartMessageModal(){
      this.$router.push({
        path:'/doctor/viewappointment/' + this.booking_id
      });
    },
    handleError(error) {
      if (error) {
        alert(error.message);
      }
    },
    turnAudioOff() {
        //const unmuteAudio = document.getElementById('unmuteAudio');
        this.activeRoom.localParticipant.audioTracks.forEach(track => {
        track.track.disable();
        this.audioBtnFlag =  false;
        });
    },
    turnAudioOn() {
      //const unmuteAudio = document.getElementById('unmuteAudio');
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
      track.track.enable();
      this.audioBtnFlag = true;
      });
    },
    turnVideoOff() {
      this.activeRoom.localParticipant.videoTracks.forEach(track => {
        track.track.disable();
        this.videoBtnFlag =  false;
      });
    },
    turnVideoOn(){
      this.activeRoom.localParticipant.videoTracks.forEach(track => {
        track.track.enable();
        this.videoBtnFlag =  true;
      });
    },
  },
  };
  </script>
  
  <style scoped>
  .PrescriptionTableMobileLayout{
    display: none;
  }
  .tabelClass{
    display: block;
  }
  .mobileTabelClass{
    display: none;
  }
  .docVideoCallMobilePatientCard{
    display: none;
  }
  whereby-embed{
    overflow:hidden;height:80vh;width:125%
  }
  .fieldset_class
  {
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
  }
  .textfield_class {
    box-sizing: border-box;
    border-radius: 8px;
  }
  .medicinetextfield_class{
      box-sizing: border-box;
    border-radius: 8px;
  }
  .timerClass{
     position: absolute;
     margin-left : 30px;
     margin-top: 10px;
     font-size:150%;
  }
    ::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
  
      /* Handle */
    ::-webkit-scrollbar-thumb {
      background: grey !important;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: grey !important;
    }
  .pub_class {
  position: absolute;
  z-index: 999;
  bottom:  20%;
  left:  1%;
  margin:  5%;
  }
  .sub_class {
  position: relative;
  z-index: 0;
  width:  100%;
  height:  80vh;
  margin:  5%;
  }
  .timmerText{
  position: absolute;
  border:2px;
  background-color: #010101;
  color: white;
  padding: 5px;
  z-index:999;
  opacity: 0.5;
  border-radius: 0px 0px 0px 4px;
  font-size: 20px;
  }
  .remote_video_container {
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: scroll;
    padding-top:  30px;
  }
  #localTrack video {
    /*width: 425px !important;*/
    background-repeat: no-repeat;
    height: 800px;
  
  }
  @media (max-width: 600px){
  .PrescriptionTableMobileLayout{
    display: block;
  }
  .prescriptionTableClass{
    display: none;
  }
  .docVideoCallNavbar{
    display: none;
  }
  .videoAndPrescriptionContainer{
    max-width: 100% !important;
    margin-top:20%;
    height: 100vh;
  }
  .remote_video_container{
    /* height: 90vh;
    max-height: 90vh; */
    width: 425px;
  }
  .PrescriptionContainer{
    width: 100vw !important;
    max-width: 100%;
    flex: 0 0 100% !important;
    overflow: unset !important;
  }
  .DocVideoCalltitleClass{
    display: none;
  }
  .patientInfoCard{
    display: none;
  }
  .docVideoCallMobilePatientCard{
    display: block;
  }
  .docVideoCallTab{
    display: none;
  }
  .persistingSymptomsClass{
    max-width: unset;
    flex: unset;
    padding:unset;
  }
  .docVideoCallMedicalDetails{
    max-width: unset;
    flex: unset;
    text-align: left;
  }
  .docVideocallTreatmentTitle{
    max-width: unset;
    flex: unset;
    text-align: left;
  }
  .medicinetextfield_class{
    width:150px
  }
  .addRemoveButton{
    display: none;
  }
  .tableCols{
    padding: 0px;
  }
  .mobileTabelClass{
    display: block;
  }
  .presentingSysmptoms{
    padding-left:12px ;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details{
    display: none !important;
  }
  .saveBtnClass{
    margin-bottom: 15px;
  }
  .attachmentsTitleClass{
    text-align: left;
  }
  .attachmentDataClass{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  }
  </style>
  